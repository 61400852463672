import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "loadingerror-states-handling"
    }}>{`Loading/Error states handling`}</h1>
    <p>{`Handling loading and error state inside `}<strong parentName="p">{`Effect`}</strong>{` like AJAX calls is a common requirement of `}<strong parentName="p">{`Effect`}</strong>{`. While there are several ways of doing this depending on your requirements.`}</p>
    <h2 {...{
      "id": "store-all-possible-states-in-single-value"
    }}>{`Store all possible states in single value`}</h2>
    <p>{`Split all possible states with multiple values in `}<strong parentName="p">{`State`}</strong>{` is bordering. The more elegant way is store all possible states in single value, TypeScript will ensure you always rendering with the right state:`}</p>
    <p><a parentName="p" {...{
        "href": "/s/sigi-recipes-error-loading-handling-86hyk?file=/src/index.tsx"
      }}>{`Error/Loading states handling Example`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-recipes-error-loading-handling-86hyk?fontsize=14&hidenavigation=1&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-recipes-error-loading-handling" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Module, EffectModule, Reducer, Effect, Action } from "@sigi/core";
import { Observable, of } from "rxjs";
import {
  exhaustMap,
  takeUntil,
  map,
  tap,
  startWith,
  catchError,
} from "rxjs/operators";

import { HttpClient } from "./http.service";

interface AppState {
  list: string[] | null | Error;
}

@Module("App")
export class AppModule extends EffectModule<AppState> {
  defaultState: AppState = {
    list: [],
  };

  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  @Reducer()
  cancel(state: AppState) {
    return { ...state, ...this.defaultState };
  }

  @Reducer()
  setList(state: AppState, list: AppState["list"]) {
    return { ...state, list };
  }

  @Effect()
  fetchList(payload$: Observable<void>): Observable<Action> {
    return payload$.pipe(
      exhaustMap(() => {
        return this.httpClient.get(\`/resources\`).pipe(
          tap(() => {
            console.info("Got response");
          }),
          map((response) => this.getActions().setList(response)),
          catchError((e) => of(this.getActions().setList(e))),
          startWith(this.getActions().setList(null)),
          takeUntil(this.getAction$().cancel)
        );
      })
    );
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Injectable } from "@sigi/di";
import { Observable, timer, throwError } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class HttpClient {
  get(_url: string): Observable<string[]> {
    return Math.random() > 0.5
      ? throwError(new TypeError("Fail to fetch"))
      : timer(3000).pipe(map(() => ["mock1", "mock2", "mock3"]));
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import "reflect-metadata";
import React from "react";
import { render } from "react-dom";
import { useModule } from "@sigi/react";
import { initDevtool } from "@sigi/devtool";

import { AppModule } from "./app.module";

function App() {
  const [{ list }, dispatcher] = useModule(AppModule);

  const loading = !list ? <div>loading</div> : null;

  const title =
    list instanceof Error ? (
      <h1>{list.message}</h1>
    ) : (
      <h1>Hello CodeSandbox</h1>
    );

  const listNodes = Array.isArray(list)
    ? list.map((value) => <li key={value}>{value}</li>)
    : null;
  return (
    <div>
      {title}
      <button onClick={dispatcher.fetchList}>fetchList</button>
      <button onClick={dispatcher.cancel}>cancel</button>
      {loading}
      <ul>{listNodes}</ul>
    </div>
  );
}

const rootElement = document.getElementById("app");
render(<App />, rootElement);

initDevtool();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      